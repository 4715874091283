<template>
  <v-card class="d-flex flex-wrap mb-5" max-width="700" flat tile>
    <v-row>
      <v-col md="3" xs="1" cols="6" >
        <span>{{$t('message.id')}}</span>
      </v-col>
      <v-col md="3" xs="1" cols="6">
        <span>{{detailCustomer.id}}</span>
      </v-col>
      <v-col md="3" xs="1" cols="6">
        <span>{{$t('message.time-use')}}: </span>
      </v-col>
      <v-col md="3" xs="1" cols="6">
        <span>{{detailCustomer.time_use}}</span>
      </v-col>
      <v-col md="3" xs="1" cols="6">
        <span>{{$t('message.name')}}: </span>
      </v-col>
      <v-col md="3" xs="1" cols="6">
        <span>{{detailCustomer.customer_name}}</span>
      </v-col>
      <v-col md="3" xs="1" cols="6">
        <span>{{$t('message.amount')}}: </span>
      </v-col>
      <v-col md="3" xs="1" cols="6">
        <span>{{detailCustomer.total_amount}}</span>
      </v-col>
      <v-col md="3" xs="1" cols="6">
        <span>{{$t('message.order-date')}}: </span>
      </v-col>
      <v-col cols="auto">
        <span>{{detailCustomer.last_order_date}}</span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "CustomerDetailInfo",
  data() {
    return {
      detailCustomer: {}
    }
  },
  props: {
    customerDetail: Object
  },
  created () {
    setTimeout(() => {
      this.detailCustomer = this.customerDetail
    }, 500)
  },
};
</script>

<style></style>
