<template>
  <v-container>
    <h2>{{ $t("message.customer-detail-title") }}</h2>

    <CustomerDetailInfo
      v-if="customerDetail"
      :customerDetail="customerDetail"
    />
    <v-pagination
      v-model="page"
      :length="lengthPage"
      :total-visible="7"
      @input="changePage"
      depressed
    ></v-pagination>
    <v-data-table
      :headers="headers"
      :items="orderResult"
      disable-sort
      :hide-default-footer="true"
    >

    <!-- REDIRECT BY CLICKED INVOICE TO ORDER DETAIL -->
    <template v-slot:[`item.order_no`]="{ item }">
      <a href="" @click.prevent="toOrderDetail(item.id)">{{ item.order_no }}</a>
    </template>
    </v-data-table> 
    <div class="d-flex flex-row-reverse mt-12 me-0" tile>
      <div class="m-2">
        <v-btn color="grey" @click="$router.go(-1)">
          {{ $t("message.back-button") }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import CustomerDetailInfo from "@/components/CustomerDetailInfo";
export default {
  name: "CustomerDetail",
  components: {
    CustomerDetailInfo,
  },
  data: () => ({
    page: 1,
  }),
  computed: {
    lengthPage() {
      return this.$store.state.customer_module.detailPagination.length_page;
    },
    orderResult(){
      return this.$store.state.customer_module.orderResult
    },
    customerDetail() {
      return this.$store.state.customer_module.customerResult;
    },
    headers() {
      return [
        {
          text: this.$t("message.order-number"),
          align: "start",
          value: "order_no",
        },
        { text: this.$t("message.total-amount"), value: "total_amount" },
        { text: this.$t("message.order-date"), value: "created_at" },
        { text: this.$t("message.status"), value: "status" },
      ];
    },
  },
  // beforeMount() {
  //   this.customerDetail;
  // },
  created() {
    this.dispatchCustomerDetail();
  },
  methods: {
    toOrderDetail(order_id) {
      this.$router.push(`/order/${order_id}`)
    },
    async dispatchCustomerDetail() {
      let id = this.$router.history.current.params.id;
      await this.$store.dispatch("customer_module/fetchCustomerDetail", {id:id});
    },
    changePage(event) {
      let id = this.$router.history.current.params.id;
  
      return this.$store.dispatch("customer_module/fetchCustomerDetail", {
        id: id,
        page: event,
      });
    },
  },
};
</script>

<style>
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}
/* .v-application .text-start,
tr td {
  text-align: center !important;
} */
thead tr th {
  color: white !important;
  font-size: 15px !important;
}
.v-data-footer-top {
  border-top: none !important;
}
.align-text-center {
  text-align: center !important;
  vertical-align: middle !important;
}
</style>
